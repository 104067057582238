<template>
  <div>
    <a-card
      style="margin-bottom: 24px"
      :head-style="{ fontWeight: 600 }"
      :body-style="{ paddingTop: 0 }"
      :bordered="false"
    >
      <div slot="title">用户基本信息</div>
      <a-row type="flex" align="middle" :gutter="[20, 0]">
        <a-col :offset="2">
          <a-row style="margin-bottom: 14px">孩子昵称：{{ userDetail.childName }}</a-row>
          <a-row
            >家长身份：{{
              {
                FATHER: '爸爸',
                MOTHER: '妈妈',
                GRANDFATHER: '爷爷',
                GRANDMOTHER: '奶奶',
                GRANDMA: '姥姥',
                GRANDPA: '姥爷',
                OTHER: '其他'
              }[userDetail.relationship]
            }}</a-row
          >
        </a-col>
        <a-col :offset="2">
          <a-row style="margin-bottom: 14px">
            <span>性别：{{ { MALE: '男', FEMALE: '女' }[userDetail.sex] }}</span>
            <span>年龄：{{ userDetail.age }}</span>
          </a-row>
          <a-row>
            <span>家长电话：{{ userDetail.cellphone }}</span>
          </a-row>
        </a-col>
        <a-col :offset="2">
          <a-row style="margin-bottom: 14px">注册日期：{{ userDetail.createTimestamp }}</a-row>
          <a-row>所在区域：{{ userDetail.area }}</a-row>
        </a-col>
      </a-row>
    </a-card>

    <a-card
      style="margin-bottom: 24px"
      :head-style="{ fontWeight: 600 }"
      :body-style="{ paddingTop: 0 }"
      :bordered="false"
    >
      <div slot="title">评估得分情况</div>
      <a-row type="flex" align="middle" :gutter="[48, 20]">
        <a-col :offset="2">
          <span
            >评估完成时间：{{ userDetail.assessmentFinishTime ? userDetail.assessmentFinishTime : '暂未完成' }}</span
          >
          <span>领域名：{{ doMain.name }}</span>
          <span>领域得分：{{ doMain.score }}</span>
          <span>领域得分率：{{ doMain.successRate }} %</span>
        </a-col>
      </a-row>
      <a-row :gutter="[48, 20]">
        <a-col :offset="2">
          <a-table bordered="true" :pagination="false" rowKey="name" :data-source="childField" :loading="listLoading">
            <a-table-column key="name" title="子分类名称" data-index="name" align="center"></a-table-column>
            <a-table-column key="score" title="得分" data-index="score" align="center"></a-table-column>
            <a-table-column
              key="successRate"
              title="得分率(%)"
              data-index="successRate"
              align="center"
            ></a-table-column>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <a-card
      style="margin-bottom: 24px"
      :head-style="{ fontWeight: 600 }"
      :body-style="{ paddingTop: 0 }"
      :bordered="false"
    >
      <div slot="title">提升计划</div>
      <a-row type="flex" :gutter="[20, 20]">
        <a-col :span="8" v-for="(item, ix) in childField" :key="ix">
          <a-card style="flex: 1" :head-style="headStyle" :bodyStyle="bodyStyle">
            <div class="card-title" slot="title">{{ item.name }}</div>
            <div v-for="(subitem, subix) in item.appEvaluationFieldSkillsVOList" :key="subix">
              <h1>{{ subitem.name }}</h1>
              <p>{{ subitem.description }}</p>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import * as toolApi from '@/api/tool'
export default {
  name: 'ToolDetail',
  data() {
    return {
      userDetail: {
        age: undefined,
        area: undefined,
        cellphone: undefined,
        childName: undefined,
        assessmentFinishTime: undefined,
        createTimestamp: undefined,
        id: undefined,
        isBuyEquipment: undefined,
        relationship: undefined,
        sex: undefined
      },
      doMain: undefined,
      childField: undefined,
      headStyle: {
        backgroundColor: '#dfe0eb'
      },
      bodyStyle: {
        height: '280px',
        overflowY: 'auto'
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this

      toolApi.getDetail({ assessmentUserId: id }).then(res => {
        this.userDetail = { ...res.data.assessmentUserManagementVO }
        this.doMain = res.data.assessmentResultVO.domain
        this.childField = res.data.assessmentResultVO.subField
      })
    }
  }
}
</script>

<style lang="less" scoped>
span {
  margin-right: 20px;
}
/deep/ .ant-table {
  width: 60%;
  margin: 20px 0;
}
</style>
